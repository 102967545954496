// @use 'sass:math';
@import '../../styles/variables';
// @import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

.Card[data-role='card'] {
  width: 25%;
  padding: 30px 25px;
  box-shadow: $boxShadow;
  background-color: #fff;
  border-radius: 8px;

  span {
    position: relative;
    top: -9px;
    display: inline-block;
    width: 41px;
    border-bottom-width: 7px;
    border-bottom-style: solid;
    border-radius: 4px;
  }

  // {heading}
  h4 {
    margin: 0.2em 0 0.6em;
    font-size: 1.25em;
    color: $Gray900;
  }

  p {
    margin: 0;
    padding: 0;
    font-family: Darker Grotesque, sans-serif;
    font-size: 1.5em;
    line-height: 1.5em;
    color: $Gray500;
  }
}
