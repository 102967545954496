// @use 'sass:math';
@import '../../styles/variables';
@import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

.Logo {
  z-index: 100;
  position: fixed;
  top: 5px;
  height: 0;

  /* COMBINED RESPONSIVE ADJUSTMENTS */

  @media /* DESKTOP RESPONSIVE ADJUSTMENTS */ (min-width: 960px),
    /* [portrait tablets] Like iPad Mini and larger; Wide e.g. Desktop */ (min-aspect-ratio: 2),
    /* [portrait tablets] Like Surface Pro 7 */ (max-aspect-ratio: 3/4) and (max-width: 319px),
    /* [portrait tablets] Like iPad Mini and larger */ (min-aspect-ratio: 3/4) and (max-aspect-ratio: 1/1) and (max-width: 1024px) {
    top: 40px;
  }

  /* [portrait phone: extreme case] Galaxy Fold */
  @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
    top: 5px;
  }

  svg {
    position: fixed;
    width: $logo_width;
    left: 7px;
    cursor: pointer;

    /* COMBINED RESPONSIVE ADJUSTMENTS */
    @media /* DESKTOP RESPONSIVE ADJUSTMENTS */ (min-width: 960px),
      /* [portrait tablets] Like iPad Mini and larger; Wide e.g. Desktop */ (min-aspect-ratio: 2),
      /* [portrait tablets] Like Surface Pro 7 */ (max-aspect-ratio: 3/4) and (max-width: 319px),
      /* [portrait tablets] Like iPad Mini and larger */ (min-aspect-ratio: 3/4) and (max-aspect-ratio: 1/1) and (max-width: 1024px) {
      left: 40px;
    }

    /* [portrait phone: extreme case] Galaxy Fold */
    @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
      left: 7px;
    }
  }
}

.Main {
  background-color: #fff;
  color: inherit;

  //   /* Compensate for mobile/tablet header height */
  //   @include contentHeightWithMobileHeader();
  //   display: grid;
  //   padding-top: 20px;
  //   background: $content-bg;
  //   place-items: center;
  //   font-weight: bold;
  //   color: #eee;
  //   transition: min-height 0.7s, height 0.7s;

  //   /* DESKTOP RESPONSIVE ADJUSTMENTS */

  //   @media (min-width: 960px) {
  //     /* Compensate for desktop header height */
  //     @include contentHeightWithFullHeader();
  //   }
}
