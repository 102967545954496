@use 'sass:math';
@import './variables';
@import './mixins/breakpoints';
@import './mixins/components';
@import './mixins/transitions';

/* Mobile First */

html,
body,
#root {
  height: 100%;
}

body,
html,
#root,
main,
section {
  margin: 0;
  padding: 0;
  background-size: 50%;
  cursor: default;
}

body,
h1,
div,
p,
span,
a {
  font-family: Merriweather, serif !important;
}

#root {
  background-color: $bg-Blue-dark;
}

section {
  width: 100%;
  max-width: 833px;
  margin: 0 auto;
}

[data-role='nowrap'] {
  white-space: nowrap;
}

hr {
  width: 100%;
  height: 3px;
  margin: 0 auto;
  background: linear-gradient(to right, transparent, transparent, #5a6dd6, #5a6dd6);
  border: 0 none;
  opacity: 0.7;
}

a {
  cursor: pointer;
  color: $PrimaryPurple;
  @include transitionAll(1.5s);

  &:hover {
    color: $link-color-hover;
  }
}

/* COMBINED RESPONSIVE ADJUSTMENTS */

@media (min-width: 540px),
  (min-aspect-ratio: 3/4) and (max-aspect-ratio: 1/1) and (min-width: 540px) {
  hr {
    width: 86%;
  }
}

/* DESKTOP RESPONSIVE ADJUSTMENTS */

@media (min-width: 960px) {
  section {
    width: 80%;
    max-width: 833px;
    margin: 0 auto;
  }
}

/* ASPECT RATIO RESPONSIVE ADJUSTMENTS */

// @media (max-aspect-ratio: 3/4) {}

/* Aspect Ratio responsive [portrait tablets] Like iPad Mini and larger */
// @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 1/1) and (max-width: 1024px) {}

@media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 4/3) {
  section {
    width: 80%;
    max-width: 833px;
    margin: 0 auto;
  }
}

// @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/4) {}

// @media (min-aspect-ratio: 5/4) and (max-aspect-ratio: 4/3) {}

// @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 256/135) {}

// @media (min-aspect-ratio: 256/135) and (max-aspect-ratio: 32/9) {}

// @media (min-aspect-ratio: 32/9) {}
