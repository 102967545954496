.TermsOfUse {
  .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1;
  }
  .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1;
  }
  .lst-kix_list_6-1 > li {
    counter-increment: lst-ctn-kix_list_6-1;
  }
  ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0;
  }
  ol.lst-kix_list_6-6.start {
    counter-reset: lst-ctn-kix_list_6-6 0;
  }
  ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0;
  }
  ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0;
  }
  .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3;
  }
  .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3;
  }
  ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0;
  }
  .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2;
  }
  ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0;
  }
  .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2;
  }
  .lst-kix_list_5-0 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_6-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-1 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4;
  }
  ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0;
  }
  .lst-kix_list_5-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_5-2 > li:before {
    content: '\0025aa  ';
  }
  .lst-kix_list_5-1 > li:before {
    content: 'o  ';
  }
  .lst-kix_list_5-7 > li:before {
    content: 'o  ';
  }
  ul.lst-kix_list_8-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-5 {
    list-style-type: none;
  }
  .lst-kix_list_5-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_5-8 > li:before {
    content: '\0025aa  ';
  }
  ul.lst-kix_list_8-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-8 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-7 {
    list-style-type: none;
  }
  .lst-kix_list_5-4 > li:before {
    content: 'o  ';
  }
  ol.lst-kix_list_6-8 {
    list-style-type: none;
  }
  .lst-kix_list_5-5 > li:before {
    content: '\0025aa  ';
  }
  ol.lst-kix_list_6-2 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_8-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_6-5 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0;
  }
  .lst-kix_list_6-1 > li:before {
    content: '' counter(lst-ctn-kix_list_6-1, lower-latin) ') ';
  }
  .lst-kix_list_6-3 > li:before {
    content: '(' counter(lst-ctn-kix_list_6-3, decimal) ') ';
  }
  .lst-kix_list_6-5 > li {
    counter-increment: lst-ctn-kix_list_6-5;
  }
  .lst-kix_list_6-8 > li {
    counter-increment: lst-ctn-kix_list_6-8;
  }
  .lst-kix_list_6-0 > li:before {
    content: '' counter(lst-ctn-kix_list_6-0, decimal) ') ';
  }
  .lst-kix_list_6-4 > li:before {
    content: '(' counter(lst-ctn-kix_list_6-4, lower-latin) ') ';
  }
  .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0;
  }
  ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0;
  }
  .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6;
  }
  .lst-kix_list_6-2 > li:before {
    content: '' counter(lst-ctn-kix_list_6-2, lower-roman) ') ';
  }
  .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5;
  }
  .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8;
  }
  ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0;
  }
  .lst-kix_list_6-8 > li:before {
    content: '' counter(lst-ctn-kix_list_6-8, lower-roman) '. ';
  }
  .lst-kix_list_6-5 > li:before {
    content: '(' counter(lst-ctn-kix_list_6-5, lower-roman) ') ';
  }
  .lst-kix_list_6-7 > li:before {
    content: '' counter(lst-ctn-kix_list_6-7, lower-latin) '. ';
  }
  ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0;
  }
  .lst-kix_list_6-6 > li:before {
    content: '' counter(lst-ctn-kix_list_6-6, decimal) '. ';
  }
  ol.lst-kix_list_1-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-4 {
    list-style-type: none;
  }
  .lst-kix_list_2-7 > li:before {
    content: '' counter(lst-ctn-kix_list_2-7, lower-latin) '. ';
  }
  .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7;
  }
  ol.lst-kix_list_1-5 {
    list-style-type: none;
  }
  .lst-kix_list_7-4 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_7-6 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_1-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-0 {
    list-style-type: none;
  }
  .lst-kix_list_2-5 > li:before {
    content: '(' counter(lst-ctn-kix_list_2-5, lower-roman) ') ';
  }
  ol.lst-kix_list_6-2.start {
    counter-reset: lst-ctn-kix_list_6-2 0;
  }
  ol.lst-kix_list_1-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_1-2 {
    list-style-type: none;
  }
  .lst-kix_list_7-2 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0;
  }
  ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0;
  }
  .lst-kix_list_7-8 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0;
  }
  ol.lst-kix_list_1-7 {
    list-style-type: none;
  }
  .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7;
  }
  ol.lst-kix_list_1-8 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0;
  }
  .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6;
  }
  .lst-kix_list_4-1 > li:before {
    content: '' counter(lst-ctn-kix_list_4-1, decimal) '. ';
  }
  .lst-kix_list_4-3 > li:before {
    content: '' counter(lst-ctn-kix_list_4-3, decimal) '. ';
  }
  .lst-kix_list_4-5 > li:before {
    content: '' counter(lst-ctn-kix_list_4-5, decimal) '. ';
  }
  .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8;
  }
  ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0;
  }
  .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5;
  }
  ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0;
  }
  .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4;
  }
  ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0;
  }
  .lst-kix_list_6-4 > li {
    counter-increment: lst-ctn-kix_list_6-4;
  }
  .lst-kix_list_6-3 > li {
    counter-increment: lst-ctn-kix_list_6-3;
  }
  ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0;
  }
  ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0;
  }
  ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0;
  }
  ol.lst-kix_list_6-1.start {
    counter-reset: lst-ctn-kix_list_6-1 0;
  }
  .lst-kix_list_1-1 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '. ';
  }
  .lst-kix_list_1-3 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '.' counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '. ';
  }
  .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8;
  }
  .lst-kix_list_1-7 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '.' counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
      counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
      counter(lst-ctn-kix_list_1-6, decimal) '.' counter(lst-ctn-kix_list_1-7, decimal) '. ';
  }
  ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0;
  }
  .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3;
  }
  .lst-kix_list_1-5 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '.' counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
      counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '. ';
  }
  .lst-kix_list_2-1 > li:before {
    content: '' counter(lst-ctn-kix_list_2-1, lower-latin) ') ';
  }
  ol.lst-kix_list_6-0.start {
    counter-reset: lst-ctn-kix_list_6-0 0;
  }
  .lst-kix_list_2-3 > li:before {
    content: '(' counter(lst-ctn-kix_list_2-3, decimal) ') ';
  }
  .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2;
  }
  ol.lst-kix_list_3-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-2 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1;
  }
  ol.lst-kix_list_3-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0;
  }
  ol.lst-kix_list_3-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-0 {
    list-style-type: none;
  }
  .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1;
  }
  ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0;
  }
  .lst-kix_list_3-0 > li:before {
    content: 'Article ' counter(lst-ctn-kix_list_3-0, upper-roman) '. ';
  }
  ul.lst-kix_list_5-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-1 > li:before {
    content: 'Section ' counter(lst-ctn-kix_list_3-0, upper-roman) '.'
      counter(lst-ctn-kix_list_3-1, decimal-leading-zero) ' ';
  }
  .lst-kix_list_3-2 > li:before {
    content: '(' counter(lst-ctn-kix_list_3-2, lower-latin) ') ';
  }
  ul.lst-kix_list_5-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-6 {
    list-style-type: none;
  }
  .lst-kix_list_8-1 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0;
  }
  .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0;
  }
  .lst-kix_list_8-2 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-0 > li {
    counter-increment: lst-ctn-kix_list_6-0;
  }
  .lst-kix_list_3-5 > li:before {
    content: '' counter(lst-ctn-kix_list_3-5, lower-latin) ') ';
  }
  ul.lst-kix_list_5-0 {
    list-style-type: none;
  }
  .lst-kix_list_3-4 > li:before {
    content: '' counter(lst-ctn-kix_list_3-4, decimal) ') ';
  }
  ul.lst-kix_list_5-3 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li:before {
    content: '(' counter(lst-ctn-kix_list_3-3, lower-roman) ') ';
  }
  ol.lst-kix_list_3-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-1 {
    list-style-type: none;
  }
  .lst-kix_list_8-0 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_3-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_5-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_3-8 {
    list-style-type: none;
  }
  .lst-kix_list_8-7 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-8 > li:before {
    content: '' counter(lst-ctn-kix_list_3-8, lower-roman) '. ';
  }
  .lst-kix_list_8-5 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_8-6 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0;
  }
  .lst-kix_list_8-3 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_3-6 > li:before {
    content: '' counter(lst-ctn-kix_list_3-6, lower-roman) ') ';
  }
  .lst-kix_list_3-7 > li:before {
    content: '' counter(lst-ctn-kix_list_3-7, lower-latin) '. ';
  }
  .lst-kix_list_8-4 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0;
  }
  .lst-kix_list_8-8 > li:before {
    content: '\0025cf  ';
  }
  ol.lst-kix_list_2-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-3 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-5 {
    list-style-type: none;
  }
  .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4;
  }
  ol.lst-kix_list_2-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-1 {
    list-style-type: none;
  }
  .lst-kix_list_4-8 > li:before {
    content: '' counter(lst-ctn-kix_list_4-8, decimal) '. ';
  }
  ol.lst-kix_list_6-4.start {
    counter-reset: lst-ctn-kix_list_6-4 0;
  }
  .lst-kix_list_4-7 > li:before {
    content: '' counter(lst-ctn-kix_list_4-7, decimal) '. ';
  }
  ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0;
  }
  ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0;
  }
  ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0;
  }
  ol.lst-kix_list_2-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-7 {
    list-style-type: none;
  }
  ol.lst-kix_list_2-8 {
    list-style-type: none;
  }
  .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3;
  }
  ol.lst-kix_list_6-3.start {
    counter-reset: lst-ctn-kix_list_6-3 0;
  }
  .lst-kix_list_7-0 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2;
  }
  ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0;
  }
  .lst-kix_list_6-2 > li {
    counter-increment: lst-ctn-kix_list_6-2;
  }
  .lst-kix_list_2-6 > li:before {
    content: '' counter(lst-ctn-kix_list_2-6, decimal) '. ';
  }
  .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7;
  }
  .lst-kix_list_2-4 > li:before {
    content: '(' counter(lst-ctn-kix_list_2-4, lower-latin) ') ';
  }
  .lst-kix_list_2-8 > li:before {
    content: '' counter(lst-ctn-kix_list_2-8, lower-roman) '. ';
  }
  .lst-kix_list_7-1 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_7-5 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_6-6 > li {
    counter-increment: lst-ctn-kix_list_6-6;
  }
  .lst-kix_list_7-3 > li:before {
    content: '\0025cf  ';
  }
  ul.lst-kix_list_7-5 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-6 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-3 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-4 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-7 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-8 {
    list-style-type: none;
  }
  .lst-kix_list_6-7 > li {
    counter-increment: lst-ctn-kix_list_6-7;
  }
  ul.lst-kix_list_7-1 {
    list-style-type: none;
  }
  ul.lst-kix_list_7-2 {
    list-style-type: none;
  }
  .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7;
  }
  ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0;
  }
  ul.lst-kix_list_7-0 {
    list-style-type: none;
  }
  .lst-kix_list_7-7 > li:before {
    content: '\0025cf  ';
  }
  .lst-kix_list_4-0 > li:before {
    content: '' counter(lst-ctn-kix_list_4-0, decimal) '. ';
  }
  .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8;
  }
  ol.lst-kix_list_6-8.start {
    counter-reset: lst-ctn-kix_list_6-8 0;
  }
  .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6;
  }
  ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0;
  }
  .lst-kix_list_4-4 > li:before {
    content: '' counter(lst-ctn-kix_list_4-4, decimal) '. ';
  }
  ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0;
  }
  .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5;
  }
  ol.lst-kix_list_6-5.start {
    counter-reset: lst-ctn-kix_list_6-5 0;
  }
  .lst-kix_list_4-2 > li:before {
    content: '' counter(lst-ctn-kix_list_4-2, decimal) '. ';
  }
  .lst-kix_list_4-6 > li:before {
    content: '' counter(lst-ctn-kix_list_4-6, decimal) '. ';
  }
  ol.lst-kix_list_4-0 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-1 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-2 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-3 {
    list-style-type: none;
  }
  .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4;
  }
  ol.lst-kix_list_6-7.start {
    counter-reset: lst-ctn-kix_list_6-7 0;
  }
  ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0;
  }
  ol.lst-kix_list_4-8 {
    list-style-type: none;
  }
  .lst-kix_list_1-0 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '. ';
  }
  ol.lst-kix_list_4-4 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-5 {
    list-style-type: none;
  }
  .lst-kix_list_1-2 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '.' counter(lst-ctn-kix_list_1-2, decimal) '. ';
  }
  ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0;
  }
  ol.lst-kix_list_4-6 {
    list-style-type: none;
  }
  ol.lst-kix_list_4-7 {
    list-style-type: none;
  }
  .lst-kix_list_1-4 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '.' counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
      counter(lst-ctn-kix_list_1-4, decimal) '. ';
  }
  ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0;
  }
  .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0;
  }
  .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6;
  }
  .lst-kix_list_1-6 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '.' counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
      counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
      counter(lst-ctn-kix_list_1-6, decimal) '. ';
  }
  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }
  .lst-kix_list_2-0 > li:before {
    content: '' counter(lst-ctn-kix_list_2-0, decimal) ') ';
  }
  ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0;
  }
  .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5;
  }
  .lst-kix_list_1-8 > li:before {
    content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal)
      '.' counter(lst-ctn-kix_list_1-2, decimal) '.' counter(lst-ctn-kix_list_1-3, decimal) '.'
      counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
      counter(lst-ctn-kix_list_1-6, decimal) '.' counter(lst-ctn-kix_list_1-7, decimal) '.'
      counter(lst-ctn-kix_list_1-8, decimal) '. ';
  }
  .lst-kix_list_2-2 > li:before {
    content: '' counter(lst-ctn-kix_list_2-2, lower-roman) ') ';
  }
  ol {
    margin: 0;
    padding: 0;
  }
  table td,
  table th {
    padding: 0;
  }
  .c9 {
    margin-left: 18.8pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c13 {
    -webkit-text-decoration-skip: none;
    color: #000000;
    text-decoration: underline;
    vertical-align: baseline;
    text-decoration-skip-ink: none;
    font-size: 12pt;
    font-family: 'Times New Roman';
    font-style: italic;
  }
  .c3 {
    margin-left: 54pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c5 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c10 {
    margin-left: 54pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial';
    font-style: normal;
  }
  .c4 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c11 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'Times New Roman';
    font-style: italic;
  }
  .c21 {
    margin-left: 54pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1;
    text-align: left;
  }
  .c29 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c0 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .c8 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: 'Times New Roman';
    font-style: normal;
  }
  .c24 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 14pt;
    line-height: 1;
    text-align: left;
  }
  .c27 {
    margin-left: 36pt;
    padding-top: 14pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c28 {
    padding-top: 14pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c6 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    text-align: left;
  }
  .c22 {
    padding-top: 15pt;
    padding-bottom: 14pt;
    line-height: 1;
    text-align: left;
  }
  .c25 {
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1;
    text-align: left;
  }
  .c16 {
    margin-left: 18.8pt;
    padding-left: 0pt;
    orphans: 2;
    widows: 2;
  }
  .c23 {
    background-color: #ffffff;
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }
  .c17 {
    orphans: 2;
    widows: 2;
  }
  .c20 {
    border: 1px solid black;
    margin: 5px;
  }
  .c19 {
    padding: 0;
    margin: 0;
  }
  .c26 {
    font-weight: 400;
  }
  .c7 {
    font-weight: 700;
  }
  .c12 {
    background-color: #ffff00;
  }
  .c14 {
    height: 12pt;
  }
  .c18 {
    font-style: italic;
  }
  .c15 {
    font-size: 13.5pt;
  }
  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 14pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  .subtitle {
    padding-top: 0pt;
    color: #000000;
    font-size: 12pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  li {
    color: #000000;
    font-size: 12pt;
    font-family: 'Times New Roman';
  }
  p {
    margin: 0;
    color: #000000;
    font-size: 12pt;
    font-family: 'Times New Roman';
  }
  h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h3 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h4 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h5 {
    padding-top: 0pt;
    color: #000000;
    font-size: 12pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
  h6 {
    padding-top: 0pt;
    color: #000000;
    font-size: 12pt;
    padding-bottom: 12pt;
    font-family: 'Times New Roman';
    line-height: 1;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
