@mixin transitions($type: '', $duration: 0.7s) {
  -webkit-transition: $type $duration;
  -moz-transition: $type $duration;
  -ms-transition: $type $duration;
  -o-transition: $type $duration;
  transition: $type $duration;
}

@mixin transitionAll($duration: 0.7s) {
  @include transitions(null, $duration);
}
