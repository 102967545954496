html body [data-arrow-iconfont='ArrowIconFont']::before {
  font-family: 'ArrowIconFont';
}

[data-arrow-icon]::before {
  font-weight: normal;
  content: attr(data-arrow-icon);
}

.scroll-arrow {
  z-index: 70;
  position: fixed;
  right: 0;
  height: 60px;
  width: 80px;
  margin: 0 0 0 -40px;
  line-height: 60px;
  text-align: center;
  font-size: 70;
  text-decoration: none;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  color: #fff;
  opacity: 0;
  // Icon
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}
.scroll-arrow-animate {
  animation: ca3_fade 2s ease-in-out infinite;
}

#arrow-up {
  top: 5px;
  transform: rotateX(180deg);
}
#arrow-down {
  bottom: 5px;
}

/*animated scroll arrow animation*/
@keyframes ca3_fade {
  0% {
    transform: translate(0, -20);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20);
    opacity: 0;
  }
}
