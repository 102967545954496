$PrimaryPurple: #485cd2;
$PerceptualPurple: #5763e9;
$Blue: #092551;
$Green: #4ea663; // bg2-3
$Red: #f2857f; // bg4
$Yellow: #eeca15; // bg5
$MedTurquoise: #1f7b9d; //bg6-7
$Gray900: #111827; // card-heading
$Gray500: #6b7280; // card-text

/*⋅.˳˳.⋅ॱ˙˙ॱ⋅.˳˳.⋅ॱ˙˙ॱᐧ.˳˳.⋅⋅.˳˳.⋅ॱ˙˙ॱ⋅.˳˳.⋅ॱ˙˙ॱᐧ.˳˳.⋅⋅.˳˳.⋅ॱ˙˙ॱ⋅.˳˳.⋅ॱ˙˙ॱᐧ.˳˳.⋅⋅.˳˳.⋅ॱ˙˙ॱ⋅.˳˳.⋅ॱ˙˙ॱᐧ.˳˳.⋅*/

$logo_width: 80px;

$bg-Purple: #5a6dd6;
$bg-Blue-med: #071d40;
$bg-Blue-dark: #05152e;
$bg-Gray-med: #333;
$bg-Gray-dark: #222;

$PurpleLightest: #8d97d2;

$link-color: #fff;
$link-color-hover: #000;

$boxShadow: -10px 10px 25px rgba(0, 0, 0, 0.25);

$pageTop-noSubNav-verticalSpacing: 5.25em;

// $search-bg: $MidnightBlack100;
// $search-text: $MidnightBlack50;
// $searchIcon: $SkyBlue100;
// $search-stats-bg: linear-gradient(
//   180deg,
//   rgba(0, 1, 24, 1) 0%,
//   rgba(0, 1, 24, 1) 25%,
//   rgba(0, 1, 24, 0.9) 50%,
//   rgba(0, 1, 24, 0.8) 72.5%,
//   transparent 100%
// );
// $search-pagination-bg: linear-gradient(
//   180deg,
//   transparent 0%,
//   rgba(16, 10, 56, 0.8) 27.5%,
//   rgba(16, 10, 56, 0.9) 37.5%,
//   rgba(16, 10, 56, 1) 75%,
//   rgba(16, 10, 56, 1) 100%
// );
