// @use 'sass:math';
@import '../../styles/variables';
@import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

/* Mobile first */

.GetTheApp {
  padding-top: $pageTop-noSubNav-verticalSpacing;
  @include article();
}

/* DESKTOP RESPONSIVE ADJUSTMENTS */

// @media (min-width: 960px) {}

/* ASPECT RATIO RESPONSIVE ADJUSTMENTS */

// @media (max-aspect-ratio: 3/4) {}

// @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 1/1) {}

// @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 4/3) {}

// @media (min-aspect-ratio: 1/1) and (max-aspect-ratio: 5/4) {}

// @media (min-aspect-ratio: 5/4) and (max-aspect-ratio: 4/3) {}

// @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 256/135) {}

// @media (min-aspect-ratio: 256/135) and (max-aspect-ratio: 32/9) {}

// @media (min-aspect-ratio: 32/9) {}
