// @use 'sass:math';
@import '../../styles/variables';
@import '../../styles/mixins/breakpoints';
// @import '../../styles/mixins/components';
@import '../../styles/mixins/transitions';

.slide1 {
  height: 100%;

  @media (max-aspect-ratio: 9/15) {
    height: 100%;
  }

  /* New Top Text Content Styling */
  div:nth-child(2) {
    z-index: 60;
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    align-content: center;
    grid-auto-columns: 80%;
    grid-auto-rows: max-content;
    justify-content: center;
    justify-items: center;
    text-align: center;

    // Branding
    > svg {
      width: 25vw;
      height: auto;

      /** Portrait - constrained by height: vw scaling */
      @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
        width: 38vw;
      }
      // Inbetween portrait phone and tablet aspsect ratios
      @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
        width: 32vw;
      }
      // Inbetween tablet aspsect ratios
      @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
        width: 22vh;
      }
      // Inbetween landscape tablet and phone aspsect ratios
      @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
        width: 32vh;
      }
      /** Landscape - constrained by height: vh scaling */
      @media (min-aspect-ratio: 15/9) {
        width: 30vh;
      }
    }

    b {
      font-weight: bolder;
      // text-shadow: 0 0.01em, 0.01em 0, 0.01em 0.01em; // VERY VERY VERY BOLD
      text-shadow: 0 0.01em;
    }

    /* left-side paragraph text */
    > p {
      margin: 0.75em 0;
      font-family: 'Darker Grotesque', sans-serif !important;

      &:first-of-type {
        margin: 1.5em 0 0;
        font-size: 3.75vw;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      font-size: 1.75vw;

      /** Portrait - constrained by height: vw scaling */
      @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
        &:first-of-type {
          font-size: 6vw;
        }
        font-size: 4.55vw; // 1.25em;
      }
      // Inbetween portrait phone and tablet aspsect ratios
      @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
        &:first-of-type {
          font-size: 5.5vw;
        }
        font-size: 3.5vw; // 1.25em;
      }
      // Inbetween tablet aspsect ratios
      @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
        &:first-of-type {
          font-size: 4.25vh;
        }
        font-size: 2.25vh; // 1.5em;
      }
      // Inbetween landscape tablet and phone aspsect ratios
      @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
        &:first-of-type {
          font-size: 4vh;
        }
        font-size: 3.25vh; // 1.25em;
      }
      /** Landscape - constrained by height: vh scaling */
      @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
        &:first-of-type {
          font-size: 4.75vh;
        }
        font-size: 3.5vh;
      }
      @media (min-aspect-ratio: 24/9) {
        &:first-of-type {
          font-size: 6vh;
        }
        font-size: 4vh;
      }
    }
  }

  /* Previous Top Content Styling */
  // width of columns
  [data-role='split'] {
    grid-auto-columns: 60%;
  }

  // .overlay
  > :nth-child(2) {
    // left-side
    [data-role='split'] {
      @media (max-width: 1024px) {
        /** Like iPad Pro */
        grid-auto-columns: 80%;
      }

      &:first-child {
        // Branding
        > svg {
          width: 25vw;
          height: auto;

          /** Portrait - constrained by height: vw scaling */
          @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
            width: 38vw;
          }
          // Inbetween portrait phone and tablet aspsect ratios
          @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
            width: 32vw;
          }
          // Inbetween tablet aspsect ratios
          @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
            width: 22vh;
          }
          // Inbetween landscape tablet and phone aspsect ratios
          @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
            width: 32vh;
          }
          /** Landscape - constrained by height: vh scaling */
          @media (min-aspect-ratio: 15/9) {
            width: 30vh;
          }
        }

        /* left-side paragraph text */
        > p {
          font-family: Merriweather, serif !important;

          &[aria-label='decoding'] {
            margin-top: 4vh;
            letter-spacing: 0.07em;
            font-size: 2.75vw;

            /** Portrait - constrained by height: vw scaling */
            @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
              font-size: 4.25vw; // 1.25em;
            }
            // Inbetween portrait phone and tablet aspsect ratios
            @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
              font-size: 3.9vw; // 1.25em;
            }
            // Inbetween tablet aspsect ratios
            @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
              font-size: 3.25vh; // 1.5em;
            }
            // Inbetween landscape tablet and phone aspsect ratios
            @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
              font-size: 4vh; // 1.25em;
            }
            /** Landscape - constrained by height: vh scaling */
            @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
              font-size: 4.25vh;
            }
            @media (min-aspect-ratio: 24/9) {
              font-size: 5vh;
            }

            span {
              font-style: italic;
            }
          }

          &[aria-label='definition'] {
            font-size: 2.75vw;

            /** Portrait - constrained by height: vw scaling */
            @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
              font-size: 3.2vw;

              @media (max-width: 280px) {
                font-size: 3.45vw;
              }
            }
            // Inbetween portrait phone and tablet aspsect ratios
            @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
              font-size: 2.5vw;

              @media (max-width: 384px) {
                font-size: 3.2vw;
              }
            }
            // Inbetween tablet aspsect ratios
            @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
              font-size: 1.45vh;

              @media (max-width: 540px) {
                font-size: 1.9vh;
              }
            }
            // Inbetween landscape tablet and phone aspsect ratios
            @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
              font-size: 2.3vh;

              @media (max-width: 533px) {
                min-width: 44em;
                margin-top: 0;
                font-size: 2.8vh;
              }
            }
            /** Landscape - constrained by height: vh scaling */
            @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
              font-size: 2.45vh;

              @media (max-width: 926px) {
                font-size: 3.5vh;
              }
            }
            @media (min-aspect-ratio: 24/9) {
              font-size: 3.4vh;
            }
          }
        }
      }
    }

    /* right-side paragraph text */
    [data-role='split']:nth-of-type(2) p {
      margin-top: 1vh;

      /** Portrait - constrained by height: vw scaling */
      @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
        font-size: 4.7vw;

        @media (max-width: 280px) {
          font-size: 5.5vw;
        }
      }
      // Inbetween portrait phone and tablet aspsect ratios
      @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
        font-size: 3.7vw;

        @media (max-width: 384px) {
          font-size: 4.7vw;
        }
      }
      // Inbetween tablet aspsect ratios
      @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
        font-size: 2.6vh; // 1.5em;

        @media (max-width: 540px) {
          font-size: 3vh;
        }
      }
      // Inbetween landscape tablet and phone aspsect ratios
      @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
        font-size: 3.5vh;

        @media (max-width: 533px) {
          font-size: 3.9vh;
        }
      }
      /** Landscape - constrained by height: vh scaling */
      @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
        font-size: 3.6vh;

        @media (max-width: 926px) {
          font-size: 5.1vh;
        }
      }
      @media (min-aspect-ratio: 24/9) {
        font-size: 4vh;
      }

      :first-child {
        margin-top: 50%;

        @media (min-width: 600px) and (max-width: 1024px) {
          /** Like Nexus 7/Blackberry Playbook - iPad Pro */
          margin-top: -2em;
        }
      }
    }
  }
}

.slide1_bg {
  background-color: $PrimaryPurple;
  background-image: url(../../assets/faces-purple.jpg);
  top: 0;

  // ASPECT-RATIO BASED RESPONSIVE

  /* Portrait Phone Aspect-Ratios (ascending): 9/32, 9/19.5, 135/256, 9/16 */
  // @include breakpointRatio(9 32, 9 16) {
  @media (max-aspect-ratio: 9/14) {
    background-image: url(../../assets/faces-purple.phone.jpg);
    // background-position: -1px !important; // Has a white left line so move it over to compensate
  }
}

// bg2

.slide2 {
  height: 100%;

  // // ASPECT-RATIO BASED RESPONSIVE

  // /* Portrait Phone Aspect-Ratios (ascending): 9/32, 9/19.5, 135/256, 9/16 */
  // // @include breakpointRatio(9 32, 9 16) {
  // @media (max-aspect-ratio: 9/15) {
  //   height: 100%;
  // }

  // .overlay
  > :nth-child(2) {
    display: block;

    // #banner-top-<X>
    > div {
      width: 100%;
      padding: calc(15vh - 1.5em) 0;
      text-align: center;

      // middle texts
      p {
        margin: 0;
        font-size: 3em;
      }
    }
  }
}

.slide2_bg {
  background-color: $Green;
  background-image: url(../../assets/faces-normal.jpg);

  @media (max-aspect-ratio: 9/14) {
    background-image: url(../../assets/faces-normal.phone.jpg);
  }
}

// bg3

.slide3 {
  height: 100%;

  // left-side
  [data-role='split']:first-child {
    // #phone-app-preview
    video {
      position: absolute;
      top: 15%;
      left: 15%; // 145px;
      height: auto;

      min-width: 70%;
      max-width: 70%;

      @media only screen and (min-width: 640px) /*and (-webkit-max-device-pixel-ratio: 1) */ {
        /* Desktop-like sizes */
        max-height: 80%;
      }
      @media (max-aspect-ratio: 3/4), only screen and (min-width: 768px) {
        /** Up to Tablet Portrait / Desktop-like sizes */
        left: 4%;
        min-width: 48%;
        max-width: 48%;
      }
      @media (max-aspect-ratio: 9/15) and (max-width: 567px),
        /* Like Mobile Portrait and Blackberry PlayBook */ (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) and (max-width: 600px) {
        min-width: 78%;
        max-width: 78%;
        left: 11%;
      }
      @media /* iPhone X */ (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
        /* iPhone X */ (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
        /* iPhone 12 P/13 P */ (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3),
        /* iPhone 13 P Max */ (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
        /* Pixel 3 XL */ (device-width: 393px) and (device-height: 786px) and (-webkit-max-device-pixel-ratio: 3.5),
        /* Pixel 5 */ (device-width: 393px) and (device-height: 851px) and (-webkit-max-device-pixel-ratio: 3.5) {
        min-width: 87%;
        max-width: 87%;
        left: 6.5%;
      }
      /** Special sizing considerations for small phones, to show the video as much as possible: */
      @media /* iPhone 4 */(min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2),
        /* iPhone 5 */ (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2),
        /* iPhone 6/6s/7/8 */ (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2),
        /* iPhone 6+/6s+/7+/8+ */ (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3),
        /* Galaxy S5 */ (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3),
        /* Nexus 5 */(device-width: 360px) and (device-height: 592px) and (-webkit-device-pixel-ratio: 3),
        /* Nexus 6/6P */(device-width: 412px) and (device-height: 732px) and (-webkit-device-pixel-ratio: 3.5),
        /* Nokia N9 */(device-width: 480px) and (device-height: 854px) and (-webkit-device-pixel-ratio: 1),
        /* Moto G4 */ (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3),
        /* Microsoft Lumia 950/Galaxy Note 3/BlackBerry PlayBook/Z30 */ (device-width: 360px) and (device-height: 640px) and (-webkit-max-device-pixel-ratio: 4),
        /* BlackBerry PlayBook */ (device-width: 600px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 1),
        /* Pixel 2*/ (device-width: 411px) and (device-height: 731px) and (-webkit-device-pixel-ratio: 2.625) {
        min-width: 68%;
        max-width: 68%;
        left: 16%;
      }
      @media /* LG Optimus L70 */ (device-width: 384px) and (device-height: 640px) and (-webkit-max-device-pixel-ratio: 1.3),
        /* Nexus 4 */(device-width: 384px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2),
        /* iPhone 5/SE */ (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
        max-width: 64%;
        min-width: 64%;
        left: 18%;
      }

      // @media (max-aspect-ratio: 9/15) and (min-width: 320px) and (max-width: 667px),
      //   (aspect-ratio: 135/256) and (min-width: 412px) and (max-width: 736px),
      //   (aspect-ratio: 135/256) and (min-width: 320px) and (max-width: 667px) {
      //   /* Like Mobile Portrait and Blackberry PlayBook */
      //   min-width: 68%;
      //   max-width: 68%;
      //   left: 16%;
      // }
    }
  }

  // right-side
  [data-role='split']:nth-child(2) {
    // #card-green
    > div {
      top: 8.84417%;

      > span {
        border-bottom-color: $Green;
      }
    }
  }
}

.slide3_bg {
  background-color: $Green;
  background-image: url(../../assets/faces-normal.jpg);

  @media (max-aspect-ratio: 9/14) {
    background-image: url(../../assets/faces-normal.phone.jpg);
  }
}

// bg4

.slide4 {
  height: 100%;

  // right-side
  [data-role='split']:nth-child(2) {
    // #card-red
    > div {
      top: 35.7139%;

      > span {
        border-bottom-color: $Red;
      }
    }
  }
}

.slide4_bg {
  background-color: $Red;
  background-image: url(../../assets/city.jpg);

  @media (max-aspect-ratio: 9/14) {
    background-image: url(../../assets/city.phone.jpg);
  }
}

// bg5

.slide5 {
  height: 100%;

  // right-side
  [data-role='split']:nth-child(2) {
    // #card-yellow
    > div {
      top: 69.3571%;

      > span {
        border-bottom-color: $Yellow;
      }
    }
  }
}

.slide5_bg {
  background-color: $Yellow;
  background-image: url(../../assets/faces-normal2.jpg);

  @media (max-aspect-ratio: 9/14) {
    background-image: url(../../assets/faces-normal2.phone.jpg);
  }
}

// bg6

.slide6 {
  // #bg-video
  video {
    filter: contrast(0.7) brightness(0.7);
  }

  // .overlay
  > :nth-child(2) {
    background-color: rgba(72, 92, 210, 0.8);
    display: block;

    // #banner-end
    > div {
      width: 100%;
      padding: calc(15vh - 1.5em) 0;
      text-align: center;

      // middle text
      p {
        margin: 1em 0 0 0;
        font-size: 3em;

        // @media (max-aspect-ratio: 9/15) {
        //   margin-top: -1.33em;
        // }
      }
    }
  }
}

.slide6_bg {
  // VIDEO BG instead of image
  background-color: $MedTurquoise;
  background-image: url(../../assets/duskbw.jpg);

  @media (max-aspect-ratio: 9/14) {
    background-image: url(../../assets/duskbw.jpg);
  }
}

// bg7

.slide7 {
  // #content-end
  > :first-child {
    grid-auto-flow: dense; // Center middle card

    // left-side
    [data-role='split']:first-child {
      section {
        margin: 2em 0;

        @media (max-aspect-ratio: 32/9) and (max-height: 280px) and (orientation: landscape) {
          /* Galaxy Fold */
          margin: 0.75em 0;
        }

        ul {
          margin: 0;

          li {
            list-style: none;
            text-indent: -1.1em; // Align wrapped lines below & with first
            margin-left: 0.5em;

            @media (max-aspect-ratio: 9/15) and (max-width: 640px) {
              margin-left: 0;
            }

            &:before {
              margin-right: 0.67em;
              font-size: 1.2em;
              content: '\2022';
            }
          }
        }
      }
      // BolderER method for fonts like Darker Grotesque (which do not add impact for bold/bolder)
      b, li {
        font-weight: bolder;
        // text-shadow: 0 0.01em, 0.01em 0, 0.01em 0.01em; // VERY VERY VERY BOLD
        text-shadow: 0 0.01em;
      }
      /* Centered paragraph text */
      > section > p {
        margin: 0 1em;
      }
      > p {
        margin: 0 1em;

        &:first-child {

        }

        @media (max-aspect-ratio: 3/4) and (max-width: 480px) {
          &:first-child {
            margin-top: 13em;
          }
        }
        @media (min-aspect-ratio: 2/3) and (max-aspect-ratio: 3/4) and (max-width: 480px),
               (max-aspect-ratio: 32/9) and (max-width: 280px) {
          /* Like iPhone 4, Galaxy Fold */
          &:first-child {
            margin-top: 19em;
          }
        }
      }

      // card-turquoise
      > div {
        top: 30%;
        // left: 8%; // 11%; // 100px;
        width: 31%; // Do not wrap heading
        height: unset;
        // right: unset;
        overflow: hidden; // Keep app store container within its parent

        // Centering
        left: unset !important;
        right: unset !important;

        @media (max-aspect-ratio: 9/15) {
          top: 47%;
          // left: unset;
          min-width: 75%;
          max-width: 75%;
          padding: 14.4px;
        }
        @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
          top: 35%;
        }
        @media (max-aspect-ratio: 9/15) and (device-width: 384px) and (device-height: 640px) {
          /* LG Optimus L70/Nexus 4 */
          top: 51%;
        }
        @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
          top: 37%;
        }
        @media (max-aspect-ratio: 3/4) and (device-width: 540px) and (device-height: 720px) {
          /* Surface Duo */
          top: 50%;
          // left: unset;
        }
        @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
          top: 36%;
        }
        @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
          top: 32%;
          // left: 3%;
          min-width: unset;
          padding: 14.4px !important;
        }
        @media (min-aspect-ratio: 24/9) and (max-aspect-ratio: 32/9) {
          top: 22%;
        }
        @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
          top: 53.5%;
        }

        > span {
          border-bottom-color: $MedTurquoise;
        }

        h4 {
          @media (max-aspect-ratio: 9/15) {
            margin: 0;
          }
        }

        > div {
          @media (max-aspect-ratio: 9/15) {
            padding: 14.4px;
          }
          @media (min-aspect-ratio: 15/9) {
            // grid-auto-flow: row;
            margin: 20px -14.4px -14.4px;
          }

          // a {
          //   &:nth-of-type(2) {
          //     padding-top: 28.8px;

          //     @media (max-aspect-ratio: 9/15) {
          //       padding-top: 14.4px;
          //     }
          //     @media (min-aspect-ratio: 15/9) {
          //       padding-top: 7.2px !important;
          //     }
          //   }
          // }
        }
      }
    }
  }

  // // right-side
  // [data-role='split']:nth-child(2) {
  //   // .event
  //   > div {
  //     position: absolute;
  //     top: 25%;
  //     right: 6%; // 100px;
  //     width: 40%;
  //     text-align: center;
  //
  //     @media (max-aspect-ratio: 9/15) {
  //       right: unset;
  //       width: 85%;
  //     }
  //     @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
  //       top: 44%;
  //     }
  //     @media (max-aspect-ratio: 9/15) and (device-width: 384px) and (device-height: 640px) {
  //       /* LG Optimus L70/Nexus 4 */
  //       top: 30%;
  //     }
  //     @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
  //       top: 46%;
  //     }
  //     @media (max-aspect-ratio: 3/4) and (device-width: 540px) and (device-height: 720px) {
  //       /* Surface Duo */
  //       top: 28%;
  //       right: unset;
  //     }
  //     @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
  //       top: 28%;
  //     }
  //     @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
  //       top: 40%;
  //     }
  //     @media (min-aspect-ratio: 24/9) and (max-aspect-ratio: 32/9) {
  //       top: 22%;
  //     }
  //     @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
  //       top: 30%;
  //     }
  //
  //     a {
  //       text-decoration: none;
  //     }
  //
  //     // .button-rsvp
  //     button {
  //       display: inline;
  //       padding: 1em;
  //       background-color: #00b1ff;
  //       border: 1px solid #485cd2;
  //       border-radius: 8px;
  //       font-size: 3vw;
  //       color: #fff;
  //       cursor: pointer;
  //       opacity: 0.8;
  //       @include transitions(opacity);
  //
  //       &:hover {
  //         opacity: 1;
  //       }
  //
  //       @media (max-aspect-ratio: 9/15) {
  //         /** Mobile Portrait */
  //         font-size: 4.5vw;
  //       }
  //       @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
  //         font-size: 2.5vh;
  //       }
  //       // Inbetween landscape tablet and phone aspsect ratios
  //       @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
  //         font-size: 3.4vh;
  //       }
  //       @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
  //         font-size: 3.5vh;
  //       }
  //       @media (min-aspect-ratio: 24/9) and (max-aspect-ratio: 32/9) {
  //         font-size: 5vh;
  //       }
  //       @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
  //         font-size: 5.25vw;
  //       }
  //     }
  //
  //     //     // Geadings
  //     //     h2 {
  //     //       font-size: 1.18vw;
  //     //
  //     //       @media (max-aspect-ratio: 9/15) {
  //     //         /** Mobile Portrait */
  //     //         margin: 1.5em 0 0;
  //     //         font-size: 4.5vw;
  //     //       }
  //     //       @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
  //     //         font-size: 2vh;
  //     //       }
  //     //       // Inbetween landscape tablet and phone aspsect ratios
  //     //       @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
  //     //         font-size: 3.4vh;
  //     //       }
  //     //       @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
  //     //         font-size: 3.5vh;
  //     //       }
  //     //       @media (min-aspect-ratio: 24/9) and (max-aspect-ratio: 32/9) {
  //     //         font-size: 5vh;
  //     //       }
  //     //       @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
  //     //         font-size: 5.25vw;
  //     //       }
  //     //     }
  //     //     h3 {
  //     //       font-size: 0.95vw;
  //     //
  //     //       @media (max-aspect-ratio: 9/15) {
  //     //         /** Mobile Portrait */
  //     //         margin: 0.5em 0 0;
  //     //         font-size: 3.5vw;
  //     //       }
  //     //       @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
  //     //         font-size: 1.6vh;
  //     //       }
  //     //       // Inbetween landscape tablet and phone aspsect ratios
  //     //       @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
  //     //         font-size: 2.75vh;
  //     //       }
  //     //       @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
  //     //         font-size: 2.8vh;
  //     //       }
  //     //       @media (min-aspect-ratio: 24/9) and (max-aspect-ratio: 32/9) {
  //     //         font-size: 4vh;
  //     //       }
  //     //       @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
  //     //         font-size: 5.25vw;
  //     //       }
  //     //     }
  //     //
  //     //     p {
  //     //       @media (max-aspect-ratio: 9/15) {
  //     //         margin: 0.5em 0;
  //     //         line-height: 1.125em;
  //     //       }
  //     //     }
  //     //
  //     //     form {
  //     //       @media (min-aspect-ratio: 9/15) {
  //     //         /** Mobile Portrait */
  //     //         margin-top: 0;
  //     //       }
  //     //
  //     //       // Form
  //     //       input,
  //     //       button {
  //     //         font-size: 1.8vw;
  //     //
  //     //         @media (max-aspect-ratio: 9/15) {
  //     //           /** Mobile Portrait */
  //     //           font-size: 3.5vw;
  //     //         }
  //     //
  //     //         @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
  //     //           font-size: 2.5vh;
  //     //         }
  //     //
  //     //         @media (min-aspect-ratio: 15/9) {
  //     //           font-size: 3vh;
  //     //         }
  //     //       }
  //     //     }
  //   }
  // }
}

.slide7_bg {
  // VIDEO BG instead of image
  background-color: $MedTurquoise;
}
