// @use 'sass:math';
@import '../../styles/variables';
// @import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

.AppStoreLinks[data-role='card'] {
  overflow: hidden; // Keep app store container within its parent
  padding: 28.8px 28.8px 0 28.8px !important;
  min-width: 17em; // Do not wrap heading

  > span {
    border-bottom-color: $MedTurquoise;
  }

  > div {
    position: relative;
    bottom: 0;
    display: grid;
    justify-content: center;
    justify-items: center;
    margin: 20px -44px 0;
    padding: 28.8px;
    background-color: #f2f2f2;

    @media (min-aspect-ratio: 15/9) {
      grid-auto-flow: column;
      margin-top: -2.2px;
      padding: 14.4px;
    }

    a {
      height: 60px;
      padding: 14.4px 0;

      // &:nth-of-type(2) {
      //   padding-top: 30px;

      //   @media (min-aspect-ratio: 15/9) {
      //     padding-top: 0 !important;
      //   }
      //   @media (min-width: 747px), (min-height: 447px) {
      //     padding-top: 10px !important;
      //   }
      // }

      @media (min-aspect-ratio: 15/9) {
        height: 40px;

        svg {
          height: 40px;
          margin: 0 -22px;
        }
      }
    }
  }
}
