@use 'sass:math';
// @import '../../styles/variables';
@import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

// @include personIllustration();

article.RSVPForm {
  text-align: center;

  h1 {
    margin-top: 0;
    padding-top: 0 !important;
  }

  h2 {
    font-family: Darker Grotesque, sans-serif;
  }

  > div > p {
    text-align: center !important;
  }
}
