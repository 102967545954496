@use 'sass:math';
@import '../../styles/variables';
// @import '../../styles/mixins/breakpoints';
@import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';
@import './scroll-down-arrow.scss';

body.parallax {
  height: 100%;

  #root,
  main,
  section {
    height: 100%;
  }

  section {
    width: inherit;
    max-width: inherit;
  }

  // Centered Logo
  [class^='Main_Logo'] {
    width: 100%;
    position: absolute;
    top: 103.025%; // 40px from the top of bg2 // Dynamically set more precisely by elementFixedAt

    svg {
      position: absolute;
      left: calc(50% - math.div($logo_width, 2));

      @media (min-width: 600px) {
        /** Like Nexus 7/Blackberry Playbook and up */
        left: 40px;
      }

      // /* [portrait phone: extreme case] Galaxy Fold */
      // @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
      //   left: calc(50% - math.div($logo_width, 2));
      // }
    }
  }

  footer {
    z-index: 80;
    position: absolute;
    left: 0;
    width: 100%;

    &.fadeIn {
      @include animatedFadeIn(animatedFadeInFooter, 0, 0.9);
      animation-name: animatedFadeInFooter;
      opacity: 0.9 !important;
    }
  }
}

.bg-container {
  margin: 0 auto;
  min-width: 100%;
  overflow: auto;
  width: 100%;
}

@include animatedFadeIn();
.fadeIn {
  @include fadeIn();
  opacity: 1 !important;
}
@include animatedFadeOut();
.fadeOut {
  @include fadeOut();
  opacity: 0 !important;
}

[class*='_slide'],
.overlay {
  overflow-y: hidden;
  color: #fff;
}

[class*='_slide'] {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

[class*='_bg__'] {
  position: absolute;
  width: inherit;
  height: 100%;
  padding: 0;
  background-attachment: fixed;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.overlay {
  z-index: 60;
  position: absolute;
  display: grid;
  width: 100%;
  height: 100%;
  grid-auto-flow: row dense;
  grid-auto-rows: 50% 50%;
  grid-auto-columns: 100%;
  align-items: center;
  justify-content: center;
  justify-items: center;
  overflow: hidden;

  @media (max-height: 869px) {
    grid-auto-rows: 60% 40%;
  }
  @media (max-width: 599px) {
    /** Like Nexus 7/Blackberry Playbook */
    grid-auto-rows: 64% 29%;
  }

  @media (min-width: 568px) {
    /** Like Nexus 7/Blackberry Playbook and up */
    grid-auto-flow: column dense;
    grid-auto-rows: 100%;
    grid-auto-columns: 50% 50%;
  }

  div[data-role='split'] {
    display: grid;
    justify-content: center;
    justify-items: center;

    p {
      font-family: Darker Grotesque, sans-serif !important;
      line-height: 1.5em;
      // text-align: justify;

      font-size: 3vw;
      strong { font-size: 3.4vw; } // <strong /> is only being used for this font-increase on slide7

      /** Portrait - constrained by height: vw scaling */
      @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
        font-size: 4.5vw;
        strong { font-size: 4.9vw; }
      }
      // Inbetween portrait phone and tablet aspsect ratios
      @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
        font-size: 5vw;
        strong { font-size: 5.4vw; }

        @media (min-height: 1080px) {
          font-size: 3.5vw;
          strong { font-size: 3.9vw; }
        }
      }
      @media (min-aspect-ratio: 135/256) and (max-width: 800px) {
        /* Like Galaxy Tab S4, Kindle Fire HDX */
        font-size: 3.35vw;
        strong { font-size: 3.75vw; }
      }
      @media /* iPhone X */ (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
        /* iPhone X */ (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
        /* iPhone 12 P/13 P */ (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3),
        /* iPhone 13 P Max */ (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
        /* Pixel 3 XL */ (device-width: 393px) and (device-height: 786px) and (-webkit-max-device-pixel-ratio: 3.5),
        /* Pixel 5 */ (device-width: 393px) and (device-height: 851px) and (-webkit-max-device-pixel-ratio: 3.5) {
        font-size: 4.25vw;
        strong { font-size: 4.65vw; }
      }
      @media (min-aspect-ratio: 135/256) and (min-width: 820px) and (max-width: 912px) {
        /* Like iPad Air, Surface Pro 7, iPad Pro */
        font-size: 3.2vw;
        strong { font-size: 3.6vw; }
      }
      @media (min-aspect-ratio: 135/256) and (min-width: 913px) and (max-width: 1024px) {
        /* Like iPad Pro */
        font-size: 3vw;
        strong { font-size: 3.4vw; }
      }
      // Inbetween tablet aspsect ratios
      @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
        font-size: 1.7vh; // 1.5em;
        strong { font-size: 2.1vh; }
      }
      // Inbetween landscape tablet and phone aspsect ratios
      @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
        font-size: 2.75vh;
        strong { font-size: 3.15vh; }
      }
      /** Landscape - constrained by height: vh scaling */
      @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
        font-size: 3vh;
        strong { font-size: 3.4vh; }
      }
      @media (min-aspect-ratio: 24/9) and (max-aspect-ratio: 32/9) {
        font-size: 4vh;
        strong { font-size: 4.4vh; }
      }
      @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
        font-size: 5.25vw;
        strong { font-size: 5.65vw; }
      }
    }
  }

  [data-role='card'] {
    position: absolute;
    padding: 28.8px;

    right: 0;
    width: 88%;
    height: 7em;
    border-radius: 0;

    /** Portrait - constrained by width: vw scaling */
    @media (min-aspect-ratio: 9/14.9), (min-aspect-ratio: 135/256) and (min-width: 568px) {
      /* Phone Portrait down to LG Optimus L70 through Galaxy Fold */
      width: 25%;
      height: unset;
      right: 9%;
      border-radius: 8px;
    }
    @media /* iPhone X */ (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
      /* iPhone X */ (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
      /* iPhone 12 P/13 P */ (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3),
      /* iPhone 13 P Max */ (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
      /* Pixel 3 XL */ (device-width: 393px) and (device-height: 786px) and (-webkit-max-device-pixel-ratio: 3.5),
      /* Pixel 5 */ (device-width: 393px) and (device-height: 851px) and (-webkit-max-device-pixel-ratio: 3.5) {
      height: 8.25em;
    }
    @media (min-aspect-ratio: 135/256) and (max-width: 800px),
      (min-aspect-ratio: 135/256) and (min-width: 820px) and (max-width: 912px),
      (min-aspect-ratio: 135/256) and (min-width: 913px) and (max-width: 1024px) {
      /* Like Galaxy Tab S4, Kindle Fire HDX and iPad Air, Surface Pro 7 and iPad Pro */
      right: 7%;
    }
    @media (min-aspect-ratio: 135/256) and (min-width: 820px) and (max-width: 912px) {
      /* Like iPad Air, Surface Pro 7 */
      padding: 28.8px 28.8px 24px 28.8px;
    }
    @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) and (max-width: 600px) {
      /* Like Blackberry PlayBook */
      width: 91%;
      right: 0;
      border-radius: 0;
    }
    @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) and (max-width: 428px) {
      width: 86.5%;
    }
    // Inbetween tablet and phone aspsect ratios
    @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 15/9) {
      right: 7%;
      width: 25%;
      height: unset;
      border-radius: 8px;

      @media (max-width: 720px) {
        padding: 14.4px;
      }
    }
    // Inbetween phone portrait and tablet portrait aspsect ratios
    @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 3/4) and (min-width: 768px) {
      right: 4%;
      width: 25%;
      height: unset;
      border-radius: 8px;
    }
    /** Landscape - constrained by height: vh scaling */
    @media (min-aspect-ratio: 15/9) and (max-width: 1200px) {
      padding: 14.4px;
    }
    @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
      width: 82%;
      height: 6em;
    }

    // Heading
    h4 {
      font-size: 2.5vw;

      @media (min-aspect-ratio: 9/24) and (max-aspect-ratio: 9/15) {
        font-size: 3vw;
      }
      @media (min-aspect-ratio: 9/15) and (max-aspect-ratio: 3/4) {
        font-size: 4vw;

        @media (min-height: 1080px) {
          font-size: 2.75vw;
        }
      }
      @media (min-aspect-ratio: 135/256) and (max-width: 800px) {
        /* Like Galaxy Tab S4, Kindle Fire HDX */
        font-size: 2.5vw;
      }
      @media /* iPhone X */ (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3),
        /* iPhone X */ (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2),
        /* iPhone 12 P/13 P */ (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3),
        /* iPhone 13 P Max */ (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3),
        /* Pixel 3 XL */ (device-width: 393px) and (device-height: 786px) and (-webkit-max-device-pixel-ratio: 3.5),
        /* Pixel 5 */ (device-width: 393px) and (device-height: 851px) and (-webkit-max-device-pixel-ratio: 3.5) {
        font-size: 3vw;
      }
      @media (min-aspect-ratio: 135/256) and (min-width: 820px) and (max-width: 912px) {
        /* Like iPad Air, Surface Pro 7 */
        font-size: 2.3vw;
      }
      @media (min-aspect-ratio: 135/256) and (min-width: 913px) and (max-width: 1024px) {
        /* Like iPad Pro */
        font-size: 2.2vw;
      }
      // Inbetween tablet aspsect ratios
      @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 4/3) {
        font-size: 1.4vh;
      }
      // Inbetween landscape tablet and phone aspsect ratios
      @media (min-aspect-ratio: 4/3) and (max-aspect-ratio: 15/9) {
        font-size: 2vh;
      }
      /** Landscape - constrained by height: vh scaling */
      @media (min-aspect-ratio: 15/9) and (max-aspect-ratio: 24/9) {
        font-size: 2.25vh;
      }
      @media (min-aspect-ratio: 24/9) {
        font-size: 4vh;
      }
      @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
        font-size: 4.25vw;
      }
    }
  }
}
