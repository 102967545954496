@import '../variables';
@import './transitions';

@mixin contentHeightWithMobileHeader() {
  min-height: calc(100vh - $headerHeightMobile);
  height: calc(100% - $headerHeightMobile);
}
@mixin contentHeightWithFullHeader() {
  min-height: calc(100vh - $headerHeightDesktop);
  height: calc(100% - $headerHeightDesktop);
}

/** nonBreakingHyphen - Add this as a child within your style block with specificity overriding
 * @example Use this code which includes a non-breaking hyphen:
 *          <span className={styles.nonBreakingHyphen}>‑</span>
 *
 * @see https://stackoverflow.com/a/8753317/2895948
 */
@mixin nonBreakingHyphen() {
  .nonBreakingHyphen {
    font-weight: lighter;
    font-size: 0.8em !important;
    letter-spacing: 0.03em;
  }
}

/** breakableLink - Breaks up [non-titled] Hyperlinks for narrow Mobile viewports */
@mixin breakableLink() {
  a:not([title]) {
    word-break: break-all;
  }
}

@mixin formField() {
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #485cd2;
  font-family: Darker Grotesque, sans-serif;
  font-size: 1em;
  outline: 0 none !important;
  color: $Blue;
  @include transitions(opacity);

  &:hover {
    opacity: 1;
  }

  // Consistent color of placeholder with phone field mask
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $Blue;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $Blue;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $Blue;
  }
}

@mixin article() {
  ul.Legal {
    display: inline-block;
    width: 100%;
    margin: 0.5em -1em 0.6em;
    padding: 0;
    list-style-type: none;
    text-align: right;

    li {
      margin: 0.5em;
      font-size: 0.9em;
    }
  }

  article {
    margin: -1em 1em;
    padding-bottom: 3em;

    > div {
      max-width: inherit !important;
      padding: 0 !important;
    }

    > div,
    p {
      background-color: transparent !important;
    }

    h1 {
      margin-bottom: 1em;
      padding: 1em 0 0;
      font-family: Merriweather, serif !important;
      font-size: 2em !important;
      // text-shadow: 1px 1px 1em $PrimaryPurple, 1px 1px 0.5em #fff;
    }
    &.TermsOfUse h1 {
      margin-bottom: 0;
    }

    table {
      > tbody > tr:first-child td {
        background-color: #eee !important;
      }

      td {
        padding: 0.5em !important;
        border-color: $Blue !important;
        border-width: 1px !important;

        p {
          text-align: left !important;
        }
      }
    }

    div {
      padding: 0.4em 0.2em;
      border-color: $bg-Blue-dark !important;
    }

    p,
    li {
      text-align: justify !important;
    }

    li,
    p,
    span,
    a {
      font-family: Darker Grotesque, sans-serif !important;
      font-size: 20px !important;
      line-height: 27px !important;
    }
  }

  /* COMBINED RESPONSIVE ADJUSTMENTS */

  @media /* DESKTOP RESPONSIVE ADJUSTMENTS */ (min-width: 540px),
    /* ASPECT RATIO RESPONSIVE ADJUSTMENTS */ /* [portrait tablets] Like Surface Pro 7 */ (max-aspect-ratio: 3/4) and (min-width: 540px),
    /* [portrait tablets] Like iPad Mini and larger */ (min-aspect-ratio: 3/4) and (max-aspect-ratio: 1/1) and (max-width: 1024px) {
    article {
      margin: 2.5em 3.5em 0;
    }

    ul.Legal {
      margin: 0.7em -3.3em;
    }
  }
}

@mixin centerContent() {
  width: 100vw;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 200%;
  background-position: bottom 0 left 45%;

  @media (min-width: 960px) {
    background-size: 100%;
    background-position: center 41%;
  }

  /* Aspect Ratio responsive [portrait tablets] Like iPad Mini and larger */
  @media (min-aspect-ratio: 3/4) and (max-aspect-ratio: 1/1) and (max-width: 1024px) {
    background-size: 140%;
    background-position: top 43% left 55%;
  }
}

@mixin personIllustration() {
  .PersonIllustration {
    margin: -0.7em 0 0.2em;
    padding-top: 1em;
    line-height: 1em;
  }
}

@mixin button() {
  opacity: 0.5;
  padding: 8px;
  border-radius: 4px;
  background: $SkyBlue100;
  border: 0;
  color: #000;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  @include transitionAll();

  &.active,
  &:hover {
    opacity: 1;
  }
}

@mixin animatedFadeOut($name: animatedFadeOut, $min: 0, $max: 1) {
  @keyframes #{$name} {
    0% {
      opacity: $max;
    }
    100% {
      opacity: $min;
    }
  }
}
@mixin animatedFadeIn($name: animatedFadeIn, $min: 0, $max: 1) {
  @keyframes #{$name} {
    0% {
      opacity: $min;
    }
    100% {
      opacity: $max;
    }
  }
}

@mixin fader($duration: 0.7s) {
  animation-name: animatedFadeOut;
  animation-duration: $duration;
  animation-iteration-count: 1; // 1 is the default
  // animation-direction: normal !important; // normal is the default
}

@mixin fadeOutInLoop($duration: 0.7s) {
  @include fader($duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@mixin fadeOut($duration: 0.7s) {
  @include fader($duration);
  animation-timing-function: ease-in-out;
}

@mixin fadeIn($duration: 0.7s) {
  @include fader($duration);
  animation-name: animatedFadeIn;
  animation-timing-function: ease-in-out;
}
