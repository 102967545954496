@use 'sass:math';
@import '../../styles/variables';
@import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

$spacing: 10px;

.ContactForm {
  margin-top: ($spacing * 3);

  input,
  textarea,
  button {
    display: block;
    @include formField();
  }

  input,
  textarea {
    width: 90%;
    max-width: 24em;
    margin: 0 auto $spacing; // 0 ($spacing * 2) math.div($spacing, 2) 0; // Commented-margin when form is inline (prev. email only)
    box-sizing: border-box;
    opacity: 0.85;

    & + div {
      margin: -$spacing auto $spacing;
      font-size: 0.9em;
      color: $PrimaryPurple;
      // When inline:
      // position: absolute;
      // bottom: -2.5em;
    }
  }

  button {
    display: block;
    width: 25%;
    margin: math.div($spacing, 2) auto 0; // -math.div($spacing, 2) auto 0; // Commented-margin for when form is inline
    background-color: $PerceptualPurple;
    cursor: pointer;
    opacity: 0.85; // Use when <form> overlays <video>
    color: #fff;
  }
}

.succeeded {
  margin: 0;
  font-size: 2.5em !important;
  font-weight: bolder;
  font-style: italic;
}
