// @use 'sass:math';
@import './variables';
// @import './mixins/breakpoints';
@import './mixins/components';
// @import './mixins/transitions';

/* Mobile First */

@include personIllustration();

.Error {
  @include article();

  width: 100%;
  height: calc(100vh - 34px);
  display: grid;
  align-content: center;
  justify-content: center;
  justify-items: center;
  margin: -1.4em 0 0;
  padding: 0;
  background: url('../assets/faces-purple.jpg') center / cover no-repeat;
  text-align: center;
  line-height: 1.5em;

  &[role='alert'] {
    height: calc(100vh + 22px);
  }

  /* DESKTOP RESPONSIVE ADJUSTMENTS */
  @media (min-width: 960px) {
    background-position: center 100%;
  }

  article {
    margin: 0;
    padding: 0;

    p {
      min-width: none;
      max-width: none;
      margin: 0 auto;
      text-align: center !important;
    }

    a {
      font-size: 1.4em !important;
    }
  }

  p,
  pre {
    min-width: 67%;
    max-width: 67%;
    color: #eee;
  }

  pre {
    padding-top: 1.5em;
    white-space: break-spaces;
    cursor: text;
  }

  a,
  button {
    display: block;
    margin: 1em auto 0;
    padding: 0.5em 0;
    font-size: 1.4em !important;
    color: #fff;
    cursor: pointer;
  }
  button {
    width: 5.6em;
    display: inline-block;
    background-color: $PrimaryPurple;
  }
}
