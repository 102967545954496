@use 'sass:math';
// @import '../../styles/variables';
@import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

@include personIllustration();

article.SupportDetails {
  > div > p {
    text-align: center !important;
  }
}
