@use 'sass:math';
@import '../../styles/variables';
// @import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

$spacing: 1em;

$bottomFade: linear-gradient(180deg, transparent 0%, $bg-Blue-dark 50%, $bg-Blue-dark 100%);

@mixin active() {
  color: $PrimaryPurple !important;
  text-shadow: 0 0 $spacing #000, 0 0 $spacing #000, 0 0 $spacing #000, 0 0 $spacing #000;
}

/* Mobile First - beginning with extreme cases for 280px wide phones like the Galaxy Fold */

.Footer {
  width: 100%;
  height: (3.5 * $spacing);
  background-color: $bg-Blue-med;
  letter-spacing: 0.066px;
  color: #fff;

  ul,
  li,
  aside,
  a {
    display: inline-block;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    width: 100%;
    overflow: hidden;
    margin-bottom: -0.5em;
    list-style-type: none;
    background-color: $bg-Blue-med;

    /** Like Galaxy Fold - iPhone 5/SE */
    @media (min-width: 280px) and (max-width: 374px) {
      text-align: center;
    }
    /** Like iPhone 6/7/8/X/13Mini */
    @media (min-width: 375px) {
      background: linear-gradient(180deg, transparent 60%, $bg-Blue-dark 90%, $bg-Blue-dark 100%);
    }
  }

  aside {
    position: absolute;
    right: 0;
    display: block;
    width: 100%;
    padding: 0 0 math.div($spacing, 4);
    background: linear-gradient(180deg, transparent 0%, $bg-Blue-dark 50%, $bg-Blue-dark 100%);
    text-align: center;
    font-family: Darker Grotesque, sans-serif;
    font-size: 1.1em;
    line-height: 0.9em;
    color: $PurpleLightest;

    @media (min-width: 375px) {
      /** Like iPhone 6/7/8/X/13Mini */
      display: inline-block;
      width: 5em;
      background: unset;
      padding: ($spacing * 1.1) math.div($spacing, 2) 0 0;
      text-align: right;
      font-size: 0.85em;
    }
    // @media (min-width: 376px) and (max-width: 464px) {
    //   /** Like iPhone 6/7/8/X/13Mini < Nokia N9 */
    //   width: unset;
    // }
    @media (min-width: 465px) {
      /** Like Nokia N9 */
      padding: math.div($spacing, 1.4) math.div($spacing, 2) 0 0;
      font-size: 1.1em;
    }
    // @media (max-aspect-ratio: 32/9) and (max-width: 280px) {
    //   /* [portrait phone: extreme case] Galaxy Fold */
    //   font-size: 0.7em;
    // }
    @media (min-width: 620px) {
      /** Like Nokia N9 */
      width: unset;
      padding: ($spacing * 1.15) math.div($spacing, 2) 0 0;
    }
  }

  a {
    padding: math.div($spacing, 1.6) 2.5vw;
    text-align: center;
    text-decoration: none;
    color: #fff;

    @media (min-width: 320px) and (max-width: 359px) {
      /** Like iPhone 5/SE - Galaxy S III */
      padding: math.div($spacing, 1.6) 3.5vw;
    }
    @media (min-width: 375px) {
      /** Like iPhone 6/7/8/X/13Mini - 12/13/13Pro */
      padding: ($spacing * 1.15) 3vw;
    }
    @media (min-width: 412px) {
      /** Like Nexus 5X/6/6P - iPhone 6P/7P/8P/XR */
      padding: ($spacing * 1.15) 4vw;
    }
    @media (min-width: 600px) {
      /** Like Nexus 7/Blackberry Playbook and up */
      padding: ($spacing * 1.15) ($spacing * 2);
    }

    &:hover {
      @include active();
    }
  }

  .active {
    @include active();
  }

  .wide {
    display: none;
  }
}
