@use 'sass:math';
// @import '../../styles/variables';
@import '../../styles/mixins/components';
// @import '../../styles/mixins/transitions';

$accent: #e8e8e8;

article.GetTheAppContent {
  > div {
    margin: -2em 0 0;

    @media (max-aspect-ratio: 2/3) {
      margin: 2em -2em 0;
    }

    [data-role='card'] {
      margin: 0 auto;
      border: 1px solid $accent !important;

      > span {
        border-bottom-color: $accent;
      }
    }
  }
}
